export class Supervisor implements ISupervisor {
    workerCode: string;
    workerApproverCode: string;
    supervisorName: string;
    workerName: string;
    workerApproverName: string;
    supervisorType: string;
    locationType: string;
    supervisorId: number;
    companyId: number;
    status: number;
    isDelete: boolean;

    constructor(data?: ISupervisor) {
        if (data) {
            this.workerCode = data.workerCode;
            this.workerApproverCode = data.workerApproverCode;
            this.supervisorName = data.supervisorName;
            this.workerName = data.workerName;
            this.workerApproverName = data.workerApproverName;
            this.supervisorType = data.supervisorType;
            this.locationType = data.locationType;
            this.supervisorId = data.supervisorId;
            this.companyId = data.companyId;
            this.status = data.status;
            this.isDelete = data.isDelete;
        }
    }
}

interface ISupervisor {
    workerCode: string;
    workerApproverCode: string;
    supervisorName: string;
    workerName: string;
    workerApproverName: string;
    supervisorType: string;
    locationType: string;
    supervisorId: number;
    companyId: number;
    status: number;
    isDelete: boolean;
}
