import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AlertController, LoadingController } from '@ionic/angular';
import { Md5 } from 'ts-md5/dist/md5';
import { StorageService } from '../../shared/services/storage.service';
import { AppService } from '../../shared/services/app.service';
import { Profile } from '../../shared/entities/profile';
import { Settings } from '../../shared/entities/settings';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
})
export class LoginPage {
  fg: FormGroup;

  constructor(
    private alertController: AlertController,
    private loadingController: LoadingController,
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private appService: AppService
  ) {
    this.fg = this.formBuilder.group({
      tenancyName: new FormControl('', Validators.required),
      username: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required),
    });
  }

  async login() {
    const loading = await this.loadingController.create({
      backdropDismiss: false,
      keyboardClose: false,
      message: 'Iniciando...',
    });
    await loading.present();
    const md5 = new Md5();
    const { tenancyName, username, password } = this.fg.value;

    const passUsuario = md5.appendStr(password).end();

    try {
      const result = await this.appService.excuteQuery(
        `PR_APP_LOGIN @COD_USUARIO = '${username}', @ALIAS = '${tenancyName}', @PASS_USUARIO = '${passUsuario}'`
      );
      if (result && result.d !== -1 && result.d.length > 0) {
        const profile = result.d[0];
        await this.storageService.set('Profile', new Profile({
          userCode: profile.cod_usuario,
          companyCode: profile.codigo_empresa,
          securityCode: profile.cod_rol_seguridad,
          email: profile.mail_usuario,
          userName: profile.nom_usuario,
          companyName: profile.nombre_empresa,
        }));
        await this.storageService.set('IsRegister', true);
      } else {
        this.showError();
      }
    } catch (error) {
      this.showError();
    } finally {
      await loading.dismiss();
    }
  }

  private async showError() {
    const alert = await this.alertController.create({
      header: 'Error',
      message: 'Lo sentimos, ha ocurrido un error intentando iniciar su sesión. Por favor verifique sus datos e intente nuevamente.',
      mode: 'ios',
      buttons: [{
        text: 'OK',
        role: 'cancel'
      }]
    });
    await alert.present();
  }
}
