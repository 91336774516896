/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, OnInit } from '@angular/core';
import { Farm } from '../../shared/entities/farm';
import { StorageService } from '../../shared/services/storage.service';
import { AppService } from '../../shared/services/app.service';
import { Profile } from '../../shared/entities/profile';
import { Supervisor } from '../../shared/entities/supervisor';
import { ControlUnit } from '../../shared/entities/control-units';
import { CostCenter } from '../../shared/entities/cost-center';
import { LoadingController } from '@ionic/angular';

@Component({
  selector: 'app-farms',
  templateUrl: './farms.page.html',
  styleUrls: ['./farms.page.scss'],
})
export class FarmsPage implements OnInit {
  profile: Profile;
  search: string;
  farms: Farm[] = [];

  constructor(
    private storageService: StorageService,
    private loadingController: LoadingController,
    private appService: AppService,
  ) {
  }

  ngOnInit() {
    this.storageService.getProfile().subscribe(async (profile: Profile) => {
      this.profile = profile;
      const result = await this.appService.excuteQuery(
        `PR_APP_SUCURSALES @COD_USUARIO = '${profile.userCode}', @COD_EMPRESA = '${profile.companyCode}'`
      );
      if (result && result.d !== -1 && result.d.length > 0) {
        for (const farm of result.d) {
          this.farms.push(new Farm({
            rut: farm.rut_razon_social,
            farmCode: farm.codigo_sucursal,
            farmName: farm.nombre_sucursal,
            address: farm.direccion,
            commune: farm.comuna,
            state: farm.region,
            companyId: farm.id_empresa,
          }));
        }
        await this.storageService.set('Farms', this.farms);
      }
    });
  }

  filter(farm: Farm) {
    if (this.search && this.search.trim().length > 0) {
      if (farm.farmName.toLowerCase().indexOf(this.search.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    }
    return true;
  }

  async selectLocation(farm: Farm) {
    await this.getAllData();
    await this.storageService.set('Farm', farm);
  }

  private async getAllData() {
    const loading = await this.loadingController.create({
      backdropDismiss: false,
      keyboardClose: false,
      message: 'Cargando...',
    });
    await loading.present();
    try {
      const resultSupervisors = await this.appService.excuteQuery(
        `M_PR_S_SUPERVISORES @codigo_empresa = '${this.profile.companyCode}'`
      );
      if (
        resultSupervisors &&
        resultSupervisors.d !== -1 &&
        resultSupervisors.d.length > 0
      ) {
        const supervisors: Supervisor[] = [];
        for (const supervisor of resultSupervisors.d) {
          supervisors.push(
            new Supervisor({
              workerCode: supervisor.codigo_trabajador,
              workerApproverCode: supervisor.codigo_trabajador_aprobador,
              supervisorName: supervisor.nom_supervisor,
              workerName: supervisor.nombre_trabajador,
              workerApproverName: supervisor.nombre_trabajador_aprobador,
              supervisorType: supervisor.tipo_supervisor,
              locationType: supervisor.tipo_ubicacion,
              supervisorId: supervisor.id_supervisor,
              companyId: supervisor.id_empresa,
              status: supervisor.estado,
              isDelete: supervisor.borrado,
            })
          );
        }
        await this.storageService.set('Supervisors', supervisors);
      }

      const resultControlUnits = await this.appService.excuteQuery(
        `M_PR_S_UNIDAD_CONTROL @cod_usuario = '${this.profile.userCode}', @codigo_empresa = '${this.profile.companyCode}'`
      );
      if (
        resultControlUnits &&
        resultControlUnits.d !== -1 &&
        resultControlUnits.d.length > 0
      ) {
        const controlUnits: ControlUnit[] = [];
        for (const controlUnit of resultControlUnits.d) {
          controlUnits.push(
            new ControlUnit({
              controlUnitCode: controlUnit.cod_unidad_control,
              controlUnitName: controlUnit.nom_unidad_control,
              companyId: controlUnit.id_empresa,
            })
          );
        }
        await this.storageService.set('ControlUnits', controlUnits);
      }

      const resultCostCenters = await this.appService.excuteQuery(
        `M_PR_S_CENTRO_COSTO @cod_usuario = '${this.profile.userCode}', @codigo_empresa = '${this.profile.companyCode}'`
      );
      if (
        resultCostCenters &&
        resultCostCenters.d !== -1 &&
        resultCostCenters.d.length > 0
      ) {
        const costCenters: CostCenter[] = [];
        for (const costCenter of resultCostCenters.d) {
          costCenters.push(
            new CostCenter({
              costCenterCode: costCenter.FtecCcosto,
              costCenterName: costCenter.FtecNombre,
              speciesCode: costCenter.FtecEspeciesR,
              varietyCode: costCenter.FtecVariedadR,
              companyId: costCenter.id_empresa,
              isActive: costCenter.activo,
              isDelete: costCenter.borrado,
            })
          );
        }
        await this.storageService.set('CostCenters', costCenters);
      }
    } catch (error) {
      console.error(error);
    } finally {
      await loading.dismiss();
    }
  }
}
