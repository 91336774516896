import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';
import { Profile } from '../entities/profile';
import { Farm } from '../entities/farm';
import { Settings } from '../entities/settings';


@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private isRegister: BehaviorSubject<boolean> = new BehaviorSubject(undefined); // starting app default as 'undefined'
  private profile: BehaviorSubject<Profile> = new BehaviorSubject(undefined); // starting app default as 'undefined'
  private farm: BehaviorSubject<Farm> = new BehaviorSubject(undefined); // starting app default as 'undefined'
  private settings: BehaviorSubject<Settings> = new BehaviorSubject(undefined); // starting app default as 'undefined'

  constructor(private platform: Platform, private storage: Storage) {
    this.platform.ready().then(async () => {
      await storage.create();
      const isRegister = await this.get('IsRegister');
      this.isRegister.next(isRegister === true);
      const profile = await this.get('Profile');
      this.profile.next(profile);
      const farm = await this.get('Farm');
      this.farm.next(farm);
      const settings = await this.get('Settings');
      this.settings.next(settings);
    });
  }

  getIsRegister(): Observable<boolean> {
    return this.isRegister.asObservable();
  }

  getProfile(): Observable<Profile> {
    return this.profile.asObservable();
  }

  getFarm(): Observable<Farm> {
    return this.farm.asObservable();
  }

  getSettings(): Observable<Settings> {
    return this.settings.asObservable();
  }

  async set(key: string, value: any) {
    await this.storage.set(key, value);
    this.activeObservables(key, value);
  }

  async get(key: string): Promise<any> {
    const value = await this.storage.get(key);
    return value;
  }

  async remove(key: string) {
    await this.storage.remove(key);
  }

  async clear() {
    await this.storage.clear();
  }

  async where<T>(table: string, handler: (value: T[]) => T[]): Promise<T[]> {
    return new Promise<T[]>((resolve, reject) => {
      const items: T[] = new Array<T>();
      this.storage.forEach((value: T[], key: string) => {
        if (table === key) {
          items.push(...handler(value));
        }
      })
        .then(() => resolve(items))
        .catch(reject);
    });
  }

  private activeObservables(key: string, value: any) {
    switch (key) {
      case 'IsRegister':
        const result = value === true;
        if (!result) {
          this.clear();
        }
        this.isRegister.next(value === true);
        break;
      case 'Profile':
        this.profile.next(value);
        break;
      case 'Farm':
        this.farm.next(value);
        break;
      case 'Settings':
        this.settings.next(value);
        break;
    }
  }
}
