import { ControlUnit } from './control-units';

export class SettingControlUnit implements ISettingControlUnit {
  controlUnit: ControlUnit;
  weight: number;

  constructor(data?: ISettingControlUnit) {
    if (data) {
      this.controlUnit = data.controlUnit;
      this.weight = data.weight;
    }
  }
}

interface ISettingControlUnit {
  controlUnit: ControlUnit;
  weight: number;
}
