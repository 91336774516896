/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable max-len */
import { Component, OnInit, ViewChild } from '@angular/core';
import { StorageService } from '../../shared/services/storage.service';
import { Profile } from '../../shared/entities/profile';
import { ControlUnit } from '../../shared/entities/control-units';
import { IonDatetime, LoadingController } from '@ionic/angular';
import { Settings } from '../../shared/entities/settings';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { format, parseISO } from 'date-fns';
import { es } from 'date-fns/locale';
import * as moment from 'moment';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.page.html',
  styleUrls: ['./settings.page.scss'],
})
export class SettingsPage implements OnInit {
  @ViewChild(IonDatetime, { static: true }) datetime: IonDatetime;
  profile: Profile;
  settings: Settings;
  controlUnits: ControlUnit[] = [];
  fg: FormGroup;
  min: string;
  max: string;
  startDate: string;
  endDate: string;
  startDateText: string;
  endDateText: string;
  logo = '';

  constructor(
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private loadingController: LoadingController,
  ) {}

  ngOnInit() {
    this.storageService.getProfile().subscribe((profile: Profile) => {
      this.profile = profile;
      this.storageService
        .getSettings()
        .subscribe(async (settings: Settings) => {
          this.settings = settings;
          this.controlUnits = await this.storageService.get('ControlUnits');
          this.fg = this.formBuilder.group({
            seasonName: new FormControl(
              this.settings?.seasonName,
              Validators.required
            ),
            seasonStart: new FormControl(
              moment(
                this.settings?.seasonStart ?? moment().startOf('year')
              ).format('yyyy-MM-DD'),
              Validators.required
            ),
            seasonEnd: new FormControl(
              moment(this.settings?.seasonEnd ?? moment().endOf('year')).format(
                'yyyy-MM-DD'
              ),
              Validators.required
            ),
            goalSeason: new FormControl(this.settings?.goalSeason ?? 1000, [
              Validators.required,
              Validators.min(this.settings?.goalDay ?? 10),
            ]),
            goalDay: new FormControl(this.settings?.goalDay ?? 10, [
              Validators.required,
              Validators.min(0),
              Validators.max(this.settings?.goalSeason ?? 1000),
            ]),
            unprocessedBoxes: new FormControl(this.settings?.unprocessedBoxes ?? 0, [
              Validators.required,
              Validators.min(0),
            ]),
            logo: new FormControl(this.settings?.logo ?? '', []),
          });
          this.logo = this.fg.controls.logo.value;
          this.min = moment(
            this.settings?.seasonStart ?? moment().startOf('year')
          ).format('yyyy-MM-DD');
          this.max = moment(
            this.settings?.seasonEnd ?? moment().endOf('year')
          ).format('yyyy-MM-DD');
          this.startDateText = format(
            parseISO(moment(this.min, 'yyyy-MM-DD').toISOString()),
            `EEEE, dd 'de' MMMM 'de' yyyy`,
            { locale: es }
          );
          this.endDateText = format(
            parseISO(moment(this.max, 'yyyy-MM-DD').toISOString()),
            `EEEE, dd 'de' MMMM 'de' yyyy`,
            { locale: es }
          );
          this.startDate = moment(
            this.fg.controls.seasonStart.value,
            'yyyy-MM-DD'
          ).format('yyyy-MM-DD');
          this.endDate = moment(
            this.fg.controls.seasonEnd.value,
            'yyyy-MM-DD'
          ).format('yyyy-MM-DD');
        });
    });
  }

  changeSeasonGoal() {
    this.fg.controls.goalDay.setValidators([
      Validators.required,
      Validators.min(0),
      Validators.max(this.fg.controls.goalSeason.value),
    ]);
  }

  changeDayGoal() {
    this.fg.controls.goalSeason.setValidators([
      Validators.required,
      Validators.min(this.fg.controls.goalDay.value),
    ]);
  }

  setStartValue(value: string) {
    this.fg.controls.seasonStart.setValue(
      format(parseISO(value), 'yyyy-MM-dd')
    );
    this.min = moment(this.fg.controls.seasonStart.value, 'yyyy-MM-DD').format(
      'yyyy-MM-DD'
    );
    this.startDate = moment(
      this.fg.controls.seasonStart.value,
      'yyyy-MM-DD'
    ).format('yyyy-MM-DD');
    this.startDateText = format(
      parseISO(value),
      `EEEE, dd 'de' MMMM 'de' yyyy`,
      { locale: es }
    );
  }

  setEndValue(value: string) {
    this.fg.controls.seasonEnd.setValue(format(parseISO(value), 'yyyy-MM-dd'));
    this.max = moment(this.fg.controls.seasonEnd.value, 'yyyy-MM-DD').format(
      'yyyy-MM-DD'
    );
    this.endDate = moment(
      this.fg.controls.seasonEnd.value,
      'yyyy-MM-DD'
    ).format('yyyy-MM-DD');
    this.endDateText = format(parseISO(value), `EEEE, dd 'de' MMMM 'de' yyyy`, {
      locale: es,
    });
  }

  async save() {
    const loading = await this.loadingController.create({
      backdropDismiss: false,
      keyboardClose: false,
      message: 'Guardando...',
    });
    await loading.present();
    this.settings = new Settings();
    this.settings.seasonName = this.fg.controls.seasonName.value;
    this.settings.seasonStart = this.fg.controls.seasonStart.value;
    this.settings.seasonEnd = this.fg.controls.seasonEnd.value;
    this.settings.goalSeason = this.fg.controls.goalSeason.value;
    this.settings.goalDay = this.fg.controls.goalDay.value;
    this.settings.logo = this.fg.controls.logo.value;
    this.settings.unprocessedBoxes = this.fg.controls.unprocessedBoxes.value;
    await this.storageService.set('Settings', this.settings);
    await loading.dismiss();
  }

  upload(event) {
    const image = event.target.files[0];

    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => {
      this.logo = reader.result as string;
      this.fg.controls.logo.setValue(this.logo);
    };
  }

  imageErrorLoad() {
    this.logo = '../../assets/imgs/logo_workbe.svg';
  }
}
