export class ControlUnit implements IControlUnit {
    controlUnitCode: string;
    controlUnitName: string;
    companyId: number;

    constructor(data?: IControlUnit) {
        if (data) {
            this.controlUnitCode = data.controlUnitCode;
            this.controlUnitName = data.controlUnitName;
            this.companyId = data.companyId;
        }
    }
}

interface IControlUnit {
    controlUnitCode: string;
    controlUnitName: string;
    companyId: number;
}