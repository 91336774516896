import * as moment from 'moment';
import { SettingControlUnit } from './setting-control-unit';

export class Settings implements ISettings {
  seasonName: string;
  seasonStart: moment.Moment;
  seasonEnd: moment.Moment;
  goalSeason: number;
  goalDay: number;
  controlUnits: SettingControlUnit[];
  logo: string;
  unprocessedBoxes: number;

  constructor(data?: ISettings) {
    if (data) {
      this.seasonName = data.seasonName;
      this.seasonStart = moment(data.seasonStart);
      this.seasonEnd = moment(data.seasonEnd);
      this.goalSeason = data.goalSeason;
      this.goalDay = data.goalDay;
      this.logo = data.logo;
      this.unprocessedBoxes = data.unprocessedBoxes;
      this.controlUnits = [];
      if (data.controlUnits) {
        for (const controlUnit of data.controlUnits) {
          this.controlUnits.push(new SettingControlUnit(controlUnit));
        }
      }
    }
  }
}

interface ISettings {
  seasonName: string;
  seasonStart: moment.Moment;
  seasonEnd: moment.Moment;
  goalSeason: number;
  goalDay: number;
  controlUnits: SettingControlUnit[];
  logo: string;
  unprocessedBoxes: number;
}
