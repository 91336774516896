export class Farm implements IFarm {
    rut: string;
    farmCode: string;
    farmName: string;
    address: string;
    commune: string;
    state: string;
    companyId: number;

    constructor(data?: IFarm) {
        if (data) {
            this.rut = data.rut;
            this.farmCode = data.farmCode;
            this.farmName = data.farmName;
            this.address = data.address;
            this.commune = data.commune;
            this.state = data.state;
            this.companyId = data.companyId;
        }
    }
}

interface IFarm {
    rut: string;
    farmCode: string;
    farmName: string;
    address: string;
    commune: string;
    state: string;
    companyId: number;
}