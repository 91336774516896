import { Component, OnDestroy } from '@angular/core';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { StorageService } from '../shared/services/storage.service';
import { Profile } from '../shared/entities/profile';
import { Farm } from '../shared/entities/farm';
import * as moment from 'moment';
import { Settings } from 'src/shared/entities/settings';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  profile: Profile;

  constructor(
    private router: Router,
    private platform: Platform,
    public menuController: MenuController,
    private alertController: AlertController,
    private storageService: StorageService
  ) {
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.storageService.getIsRegister().subscribe((isRegister: boolean) => {
        if (isRegister !== undefined) {
          if (isRegister) {
            this.storageService.getProfile().subscribe((profile: Profile) => {
              this.profile = profile;
              if (profile !== undefined) {
                this.storageService.getFarm().subscribe((farm: Farm) => {
                  if (farm !== undefined) {
                    if (farm) {
                      this.storageService
                        .getSettings()
                        .subscribe((settings: Settings) => {
                          if (settings !== undefined) {
                            if (settings) {
                              this.router.navigate([''], {
                                replaceUrl: true,
                              });
                            } else {
                              this.router.navigate(['settings'], {
                                replaceUrl: true,
                              });
                            }
                          }
                        });
                    } else {
                      this.router.navigate(['farms'], { replaceUrl: true });
                    }
                  }
                });
              }
            });
          } else {
            this.router.navigate(['login'], { replaceUrl: true });
          }
        }
      });
    });
  }

  async logout() {
    let yields = await this.storageService.get('Yields');
    yields = yields ? yields : [];
    let syields = await this.storageService.get('SuccessYields');
    syields = syields ? syields : [];
    if (yields.length !== syields.length) {
      const alert = await this.alertController.create({
        header: 'Error',
        mode: 'ios',
        message: 'menu.logoutError',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
          },
        ],
      });
      await alert.present();
    } else {
      const alert = await this.alertController.create({
        header: 'menu.alert',
        mode: 'ios',
        message: 'menu.logoutMsg',
        buttons: [
          {
            text: 'menu.yes',
            handler: async () => {
              await this.storageService.clear();
              location.reload();
            },
          },
          {
            text: 'No',
            role: 'cancel',
          },
        ],
      });
      await alert.present();
    }
  }
}
