/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { HttpOptions } from '../entities/http-options';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private url: string;

  constructor(private http: HttpService) {
    this.url = environment.urlAppApi;
  }

  async excuteQuery(query: string) {
    try {
      const url = this.url + 'Config/exc_query';
      const options = new HttpOptions(url, 'POST', false);
      options.body({
        str_SQL: query,
      });
      const result = await this.http.makeCall(options);
      if (result) {
        return result;
      }
    } catch (error) {
      console.log('ExcuteQuery error', error);
    }
    return null;
  }
}
