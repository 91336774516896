export class CostCenter implements ICostCenter {
    costCenterCode: string;
    costCenterName: string;
    speciesCode: string;
    varietyCode: string;
    companyId: number;
    isActive: number;
    isDelete: number;
    displayName: string;

    constructor(data?: ICostCenter) {
        if (data) {
            this.costCenterCode = data.costCenterCode;
            this.costCenterName = data.costCenterName;
            this.speciesCode = data.speciesCode;
            this.varietyCode = data.varietyCode;
            this.companyId = data.companyId;
            this.isActive = data.isActive;
            this.isDelete = data.isDelete;
            this.displayName = `${data.costCenterCode} - ${data.costCenterName}`;
        }
    }
}

interface ICostCenter {
    costCenterCode: string;
    costCenterName: string;
    speciesCode: string;
    varietyCode: string;
    companyId: number;
    isActive: number;
    isDelete: number;
    displayName?: string;
}